$(document).on('turbolinks:load', function() {

  $(".is-success").fadeOut(10000);
  $(".is-danger").fadeOut(10000);

  //GET THE DEPARTMENT ID AND LOAD THE POSITIONS
  var saved_position = {};
  var position_options, selected_value;
  $("#candidate_department_id").change(function() {
    position_options = '<option value="" selected="selected">Loading...</option>';
    $("#candidate_position_id").html(position_options);
    selected_value = $(this).val();
    $.ajax({
      url: '/admin/positions/positions_by_department/' + selected_value,
      type: 'GET',
      dataType: 'json',
      async: true
    }).done(function(data, status){
      position_options = '<option value="" selected="selected">Please Pick...</option>';
      // Parse through the values
      $.each(data, function(index, value){
        position_options += '<option value="' + value.id + '">' + value.title + '</option>';
      });
      // Populate the second select menu
      $("#candidate_position_id").html(position_options);
      saved_position[selected_value] = position_options;
    }).fail(function(data){
      position_options = '<option value="" selected="selected">No position found..</option>';
      $("#candidate_position_id").html(position_options);
      //$("#question_1").hide();
      //$("#question_2").hide();
      //$("#question_3").hide();
    }); 
  });

  //GET THE POSITION ID AND LOAD THE CANDIDATES
  var saved_brand_group = {};
  var brand_position_options, selected_value;
  $("#brand_position_id").change(function() {
    brand_position_options = '<option value="" selected="selected">Loading...</option>';
    $("#brand_candidate_id").html(position_options);
    selected_value = $(this).val();
    $.ajax({
      url: '/admin/brands/candidates_by_position/' + selected_value,
      type: 'GET',
      dataType: 'json',
      async: true
    }).done(function(data, status){
      brand_position_options = '<option value="" selected="selected">Please Pick...</option>';
      // Parse through the values
      $.each(data, function(index, value){
        brand_position_options += '<option value="' + value.id + '">' + value.first_name + ' ' + value.last_name + '</option>';
      });
      // Populate the second select menu
      $("#brand_candidate_id").html(brand_position_options);
      saved_brand_group[selected_value] = brand_position_options;
    }).fail(function(data){
      saved_brand_group = '<option value="" selected="selected">No position found..</option>';
      $("#brand_candidate_id").html(brand_position_options);
    }); 
  });
  
  //GET THE BONUS QUESTIONS AFTER SELECTING A POSITION
  var question_value;
  $("#candidate_position_id1").change(function() {
    question_value = $(this).val();
    $.ajax({
      url: '/admin/positions/screener_questions_by_position/' + question_value,
      type: 'GET',
      dataType: 'json',
      async: true
    }).done(function(data, status) {
      if(data.screener_question_1 != "") {
        $('#screener_question_1_title').text(data.screener_question_1);
        $("#question_1").show();
      } else {
        $("#question_1").hide();
      };
      if(data.screener_question_2 != "") {
        $('#screener_question_2_title').text(data.screener_question_2);
        $("#question_2").show();
      } else {
        $("#question_2").hide();
      };
      if(data.screener_question_3 != "") {
        $('#screener_question_3_title').text(data.screener_question_3);
        $("#question_3").show();
      } else {
        $("#question_3").hide();
      };
    }).fail(function(data){
      $("#question_1").hide();
      $("#question_2").hide();
      $("#question_3").hide();
    }); 
  });

  //GET THE DEPARTMENT ID AND LOAD THE POSITIONS
  var assign_url;
  //var position_options, selected_value;
  $("#assign-candidate-button").click(function() {
    assign_url = $(this).attr("href");
    $.ajax({
      url: assign_url,
      type: 'GET',
      dataType: 'json',
      async: true
    }).done(function(data, status){
      $('#brand_role_id').value(data.role_id);
    }).fail(function(data){
    }); 
  });

});

