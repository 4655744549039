import sortable  from  "html5sortable/dist/html5sortable.cjs"
import Rails from '@rails/ujs'

$(document).on('turbolinks:load', function() {
  sortable('#statuses', {
    items: 'tr'
  });
  if (typeof sortable('#statuses')[0] != 'undefined'){
    sortable('#statuses')[0].addEventListener('sortupdate', function(e) {
      var dataIDList = $(this).children().map(function(index){
         $(this).find( ".position" ).text(index + 1)
         return "statuses[]=" + $(this).data("id");
      }).get().join("&");
      Rails.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: dataIDList,
        });
    });
  }
})

